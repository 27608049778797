import React, { useCallback, useEffect, useRef } from 'react';

type VideoProps = {
    alt?: string;
    url: string;
    onLoadedMetadata?: (duration: number) => void;
    onTimeUpdate?: (currentTime: number, duration: number) => void;
};

export const Video: React.FC<VideoProps> = React.memo(
    ({ alt, url, onLoadedMetadata, onTimeUpdate }) => {
        const videoRef = useRef<HTMLVideoElement>(null);

        const memoizedOnLoadedMetadata = useCallback(() => {
            const videoElement = videoRef.current;
            if (videoElement && onLoadedMetadata) {
                onLoadedMetadata(videoElement.duration);
            }
        }, [onLoadedMetadata]);

        const memoizedOnTimeUpdate = useCallback(() => {
            const videoElement = videoRef.current;
            if (videoElement && onTimeUpdate) {
                onTimeUpdate(videoElement.currentTime, videoElement.duration);
            }
        }, [onTimeUpdate]);

        useEffect(() => {
            const videoElement = videoRef.current;
            if (videoElement) {
                videoElement.addEventListener(
                    'loadedmetadata',
                    memoizedOnLoadedMetadata,
                );
                videoElement.addEventListener(
                    'timeupdate',
                    memoizedOnTimeUpdate,
                );
            }
            return () => {
                if (videoElement) {
                    videoElement.removeEventListener(
                        'loadedmetadata',
                        memoizedOnLoadedMetadata,
                    );
                    videoElement.removeEventListener(
                        'timeupdate',
                        memoizedOnTimeUpdate,
                    );
                }
            };
        }, [memoizedOnLoadedMetadata, memoizedOnTimeUpdate]);

        useEffect(() => {
            const videoElement = videoRef.current;

            const playVideo = () => {
                if (videoElement && videoElement.paused) {
                    videoElement.play().catch(() => {
                        console.error('Error automatically playing video');
                    });
                }
            };

            if (videoElement) {
                playVideo();
            }

            return () => {
                if (videoElement) {
                    videoElement.pause();
                    videoElement.currentTime = 0;
                    videoElement.load(); // this resets the video element
                }
            };
        }, [videoRef]);

        return (
            <video
                ref={videoRef}
                className="NativeVideo__video"
                title={alt || 'Native video content'}
                src={url}
                controls
                autoPlay={false}
                loop
                playsInline
                onClick={(e) => e.stopPropagation()}
                controlsList="nodownload nofullscreen"
            />
        );
    },
);
