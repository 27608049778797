import { initializeErrorReporting } from '@eventbrite/error-reporting';

import { emitEventForExternalTab } from '@eventbrite/ads';
import {
    getLocaleOverride,
    getParentLocale,
    setupAsync,
} from '@eventbrite/i18n';
import type { Event } from '@sentry/types';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { AppTracking } from './appTracking';
import { getShareComponentProps } from './components/ShareEvent/ShareEventRoot';
import {
    getStableIdForInitialization,
    initializeStatsigSdk,
} from './experimentation';
import { ServerApp, type ServerAppProps } from './server';
import './styles/listings.scss';
import type { AppProps } from './types';
import { isRepeatingEvent, updateRecentViews } from './utils';

import('intersection-observer');

// retrieve data from server to hydrate on the client
const props: AppProps = window.__SERVER_DATA__ || {};

const parentLocale = getParentLocale(
    getLocaleOverride() || props.env.localeInfo.locale,
);

const SENTRY_TRACES_SAMPLE_RATE = 0.5;

const ignoredErrors = [
    /Script error for "analyticsjs"/i,
    /Script error for "grylls"/i,
    /Script error for "grylls_config"/i,
    /Load timeout for modules: grylls/i,
    /heap.resetIdentity is not a function/i,
    /Error calling eventclick/i,
    /al_onPoststitialDismiss/i,
    /al_onAdViewRendered/i,
    /TypeError: Load failed/i,
    /SyntaxError: Unexpected token =/i, //Branch.io https://sentry.io/organizations/eventbrite-eng/issues/3428328229/?project=6240753
    /XDI connection to https:\/\/synctranscend.eventbrite.com/i, // https://eventbrite.atlassian.net/browse/PRIV-197
];

const ignoreError = (event: Event) => {
    const errorMessage = event?.exception?.values?.[0]?.value;

    if (errorMessage) {
        const isAnIgnoredEvent =
            ignoredErrors.findIndex((ignoredError) =>
                ignoredError.test(errorMessage),
            ) !== -1;

        return isAnIgnoredEvent;
    }

    return false;
};

const main = async () => {
    emitEventForExternalTab(props.event.id);
    const stableId = getStableIdForInitialization(props.stableId);
    const serverAppProps: ServerAppProps = {
        event: {
            ...props.event,
            isRepeating: isRepeatingEvent(props),
        },
        env: props.env,
        request: props.request,
        footerLinks: props.footerLinks,
        eventDescription: props.components.eventDescription,
        eventDetails: props.components.eventDetails,
        organizer: props.organizer,
        settings: props.settings,
        tags: props.components.tags,
        organizerPanelLayout: props.components.organizerPanel,
        eventMapLayout: props.components.eventMap,
        checkoutWidget: props.components.checkoutWidget,
        conversionBar: props.components.conversionBar,
        eventHero: props.components.eventHero,
        user: props.user,
        notificationBar: props.components.notificationBar,
        shareComponentProps: getShareComponentProps(props),
        expiredEventsPage: props.components.relatedEventsExpiredPage,
        remindMe: props.components.remindMe,
        statsig: {
            ...props.statsig,
            stableId,
        },
        urgencySignals: props.urgencySignals,
        highlights: {
            ...props.highlights,
            ...props['event_listing_response'].highlights,
        },
        gaCategory: props.gaSettings.category,
        followOrganizer: props.components.followOrganizer,
        contactOrganizer: props.components.contactOrganizer,
        gaSettings: props.gaSettings,
        salesStatus: props.event.salesStatus.sales_status,
        appProps: props,
        tickets: props['event_listing_response']?.tickets,
        featureFlags: props['event_listing_response']?.featureFlags,
        eventSessionResponse: props['event_listing_response']?.eventSessions,
        nativeVideo: props['event_listing_response']?.video,
        parentEventId: props['event_listing_response']?.parentEventId,
    };

    updateRecentViews(props.event);

    try {
        initializeErrorReporting({
            dsn: props.settings.sentryDNS,
            tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
            ignoreError,
            metaData: {
                tags: {
                    event_id: props.event.id,
                },
            },
        });

        if (parentLocale !== 'en_US') {
            try {
                await setupAsync({
                    parentLocale,
                    translations:
                        /* webpackChunkName: "listings-i18n-translation" */ import(
                            `./i18n/translations/${parentLocale}.json`
                        ),
                });
            } catch (e) {
                console.error(
                    'Failed to load parent locale translation. Falling back to en_US',
                    e,
                );
            }
        }

        await initializeStatsigSdk(props, stableId);
    } finally {
        const rootElement = document.getElementById('root');
        if (rootElement) {
            hydrate(
                <ServerApp {...serverAppProps} />,
                document.getElementById('root'),
            );
        }
        render(
            <AppTracking {...props} />,
            document.getElementById('listings-root'),
        );
    }
};
main();
