import { gettext } from '@eventbrite/i18n';
import { LoadingSkeleton } from '@eventbrite/loading-skeleton';
import React, { useContext, useRef } from 'react';
import {
    EventBasicInformation,
    EventBasicInformationContext,
} from '../../contexts';
import { useScrollOnCommand } from '../../contexts/ScrollContext';
import { STATSIG_EVENT_NAMES } from '../../experimentation';
import { useOtherEventsYouMayLike } from '../RelatedEvents/OtherEventsYouMayLike';
import { useRelatedEvents } from '../RelatedEvents/RelatedEvents';
import { EventsList as ListingsEventsList } from '../_shared/EventsList/EventsList';
import {
    Trackable,
    useExpiredEventsTrackingContextFn,
} from './ExpiredEventsTracking';

export const EnhancedExpiredEventsList: React.FunctionComponent<{
    isAuthenticated: boolean;
    locale: string;
    affCode: string;
    organizer: {
        name: string;
        profilePicture?: string;
        id: string;
    };
    forceTwoColumnLayout?: boolean;
}> = ({
    isAuthenticated,
    locale,
    affCode,
    organizer,
    forceTwoColumnLayout,
}) => {
    const { id: eventId } = useContext(
        EventBasicInformationContext,
    ) as EventBasicInformation;

    const ref = useRef<null | HTMLDivElement>(null);
    useScrollOnCommand(ref, 'relatedEvents');

    const { eventsSameOrganizer = [], isLoading } = useRelatedEvents({
        organizerId: organizer.id,
        eventId,
        isAuthenticated,
    });
    const { events: allRelatedEvents, removeEvent } = useOtherEventsYouMayLike({
        eventId,
        isAuthenticated,
        suspense: false,
        eventsToRemove: eventsSameOrganizer,
    });
    const getTrackingContext =
        useExpiredEventsTrackingContextFn(allRelatedEvents);

    const allEvents = [...eventsSameOrganizer, ...allRelatedEvents];

    if (isLoading) {
        return (
            <div ref={ref} data-testid="enhanced-expired-events-list">
                <LoadingSkeleton height="452px" />
            </div>
        );
    }
    return (
        <Trackable events={allEvents}>
            <div ref={ref} data-testid="enhanced-expired-events-list">
                {eventsSameOrganizer?.length > 0 && (
                    <ListingsEventsList
                        statsigEventMetrics={{
                            eventClick:
                                STATSIG_EVENT_NAMES.LISTING_MORE_ORGANIZER_EVENT_CLICK,
                            eventLike:
                                STATSIG_EVENT_NAMES.LISTING_MORE_ORGANIZER_EVENT_LIKE,
                        }}
                        isAuthenticated={isAuthenticated}
                        locale={locale}
                        affCode={affCode}
                        title={gettext('More %(organizerName)s events', {
                            organizerName: organizer.name,
                        }).toString()}
                        events={eventsSameOrganizer}
                        forceTwoColumnLayout={forceTwoColumnLayout}
                    />
                )}
                {allRelatedEvents.length > 0 && (
                    <ListingsEventsList
                        statsigEventMetrics={{
                            eventClick:
                                STATSIG_EVENT_NAMES.LISTING_RELATED_EVENT_CLICK,
                            eventLike:
                                STATSIG_EVENT_NAMES.LISTING_RELATED_EVENT_LIKE,
                        }}
                        isAuthenticated={isAuthenticated}
                        locale={locale}
                        affCode={affCode}
                        title={gettext('Other events you may like').toString()}
                        events={allRelatedEvents}
                        forceTwoColumnLayout={forceTwoColumnLayout}
                        promotedActionsProps={{
                            getTrackingContext,
                            onRemoveEvent: removeEvent,
                        }}
                    />
                )}
            </div>
        </Trackable>
    );
};
